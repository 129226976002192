<template>
  <div>
    <div class="recharge">
      <div class="recharge-box">
        <div class="recharge-box-p">充值分析图</div>
      </div>
      <div class="unit-ri">
        <div class="block">
          <el-date-picker
            v-model="value2"
            type="daterange"
            align="right"
            unlink-panels
            @change="handleDate"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <div class="unit">单位:{{unit}}</div>
      </div>
    </div>

    <div id="echarts" :style="{ width: '1200px', height: '500px' }"></div>

  </div>
</template>


<script>
import { ranking } from "../api/vip";

import { Loading } from 'element-ui';
export default {
  data() {
    return {
      optipns: {
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          right: 0,
          top: 200,
          margin: [0, 250, 0, 0],
          data: ["余额线", "收入线", "使用线"],
        },
        grid: {
          //
          left: "3%",
          right: "13%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          // X轴
          type: "category",
          boundaryGap: false,
          data:[],
          // data: this.day,
          splitLine: {
            show: false,
            lineStyle: {
              color: ["#ccc"],
              width: 1,
              type: "dotted",
            },
          },
          axisTick: {
            show: false, // x轴线上的横线
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#999", // 更改坐标轴文字颜色
              fontSize: 14, // 更改坐标轴文字大小
            },
          },
          axisLine: {
            //x轴线上的横线 隐藏
            show: false,
          },
        },
        yAxis: {
          // y 轴
          splitNumber: 3, // y轴
          type: "value",
          boundaryGap: false,
          axisTick: {
            show: false, // y轴线上的横线
          },
          axisLine: {
            // y轴
            show: false,
          },
          splitLine: {
            // 网格线 y轴的颜色 和虚线
            show: true,
            lineStyle: {
              color: ["#ccc"],
              width: 1,
              type: "dashed",
            },
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#999", // 更改坐标轴文字颜色
              fontSize: 14, // 更改坐标轴文字大小
            },
            // Y轴上的保留一位数
            formatter:function (value, index) {
                return value.toFixed(0);
            }
          },
        },
        color: ["#c23531", "#2f4554", "#61a0a8"],
        series: [
          {
            name: "余额线",
            type: "line",
            data:[],
            //  data:  this.datap,  //   111datap
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 2,
                  type: "solid", // 'dotted'虚线 'solid'实线
                  color: "#0E9CFF",
                },
              },
              color: "#0E9CFF",
            },
            markLine: {
                data: [
                    {type: 'average', name: '平均值'}
                ]
            },
            color: "#0E9CFF",
          },
          {
            name: "收入线",
            type: "line",
            data:[],
            //  data: this.datae,
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 2,
                  type: "solid", // 'dotted'虚线 'solid'实线
                  color: "#F48503",
                },
              },
              borderWidth: 1,
              color: "#F48503",
            },
            markLine: {
                data: [
                    {type: 'average', name: '平均值'}
                ]
            },
            color: "#F48503",
          },
          {
            name: "使用线",
            type: "line",
            data:[],
            // data: this.datax,
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 2,
                  type: "solid", // 'dotted'虚线 'solid'实线
                  color: "#999999",
                },
              },
              color: "#999999",
            },
            markLine: {
                data: [
                    {type: 'average', name: '平均值'}
                ]
            },
            color: "#999999",
          },
        ],
      },
      unit:'元',
      maxDate: null,
      minDate: null,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
        onPick: ({ maxDate, minDate }) => {
          // 当我们选择两个值的时候，就认为用户已经选择完毕
          if (maxDate != null && minDate != null) {
            this.maxDate = maxDate;
            this.minDate = minDate;
          }
        },
        disabledDate: (time) => {
          const maxDate = this.maxDate;
          const minDate = this.minDate;
          if (maxDate != null && minDate != null) {
            const days = maxDate.getTime() - minDate.getTime();
            this.maxDate = null;
            this.minDate = null;
            if (parseInt(days / (1000 * 60 * 60 * 24)) > 30) {
              this.$message.error("不能超过一个月的日期");
              this.$nextTick(() => {
                this.value2 = null;
              });
            }
            return false;
          } else {
            return time.getTime() > Date.now();
          }
        },
      },
      value2: null,
loading: true
    };
  },
  created() {
   this.GEECHAENLK();
    // this.GetData();
  },
  mounted() {

  },
  methods: {
    // GetData(){
    //   console.log(111111111111)
    //      const loading = this.$loading({
    //             lock: true,//lock的修改符--默认是false
    //             text: 'Loading',//显示在加载图标下方的加载文案
    //             spinner: 'el-icon-loading',//自定义加载图标类名
    //             background: 'rgba(0, 0, 0, 0.7)',//遮罩层颜色
    //             target: document.querySelector('.recharge'),//loadin覆盖的dom元素节点,
    //     });
    //     //成功回调函数停止加载
    //     setTimeout(() => {
    //         loading.close();
    //     }, 3000)
    // },

    Dark(){
      var dom = document.getElementById("echarts");
      var myChart = this.$echarts.init(dom);
      myChart.setOption(this.optipns);
    },
    GEECHAENLK() {
      ranking({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        },
      }).then((res) => {
        //声明
        let dateX = JSON.parse(res.data.body.data).date_list;
        let datey = JSON.parse(res.data.body.data).data_list;
        let ser = this.optipns.series[0].data;
        console.log(datey)
        // dateY = JSON.stringify(JSON.parse(res.data.body.data).data_list)
        // for(let i = 0; i < dateY.length; i++) {
        //   datap.push(dateY[i].amt_add)
        // }
        this.optipns.series[0].data = datey.map((ki) => {
          // 网格X轴
          return Number(ki.amt_end); // 余额
          console.log(ki.amt_add)
        });
        // console.log(this.optipns.series[0].data,'aaaaaaaaaaaaaaaaaaaaaaaaaaaa')


       // 判断 余额线最大值 算出最大值是什么单位
        function maxArr(arr){
　　      return Math.max.apply(Math,arr);
        }
        let MAX = maxArr(this.optipns.series[0].data)
        this.optipns.series[2].data = datey.map((si) => {
          // 网格X轴
          return Number(si.amt_sub); // 使用
        });
        this.optipns.series[1].data = datey.map((li) => {
          // 网格X轴
          return Number(li.amt_add); // 收入
        });
        console.log(res)
        //  console.log(datae)
        let NewdateX = dateX.map((item, index) => {
          // X轴日期
          return item.split("-", 3);
        });
        this.optipns.xAxis.data = NewdateX.map((item) => {
          return item[2] + "日";
        });
         this.Dark();
      });
    },
    handleDate() {
      ranking({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          date_start: this.value2[0],
          date_end: this.value2[1],
        },
      }).then((res) => {
        //声明
        let dateX = JSON.parse(res.data.body.data).date_list;
        let datey = JSON.parse(res.data.body.data).data_list;
        // dateY = JSON.stringify(JSON.parse(res.data.body.data).data_list)
        // for(let i = 0; i < dateY.length; i++) {
        //   datap.push(dateY[i].amt_add)
        // }

        this.optipns.series[0].data = datey.map((ki) => {
          // 网格X轴
          return Number(ki.amt_add); // 余额
        });
        function maxArr(arr){
　　      return Math.max.apply(Math,arr);
        }
        let MAX = maxArr(this.optipns.series[0].data)
        this.optipns.series[2].data = datey.map((si) => {
          // 网格X轴
          return Number(si.amt_sub); // 使用
        });

        this.optipns.series[1].data = datey.map((li) => {
          // 网格X轴
          return Number(li.amt_end); // 收入
        });
        //  console.log(datae)
        let NewdateX = dateX.map((item, index) => {
          // X轴日期
          return item.split("-", 3);
        });
        this.optipns.xAxis.data = NewdateX.map((item) => {
          return item[2] + "日";
        });
         this.Dark();
      });
    }
  },
};
</script>

<style>
#echarts {
  margin: 0 auto;
}
.recharge {
  width: 1200px;
  margin: 0 auto;
}
.recharge-box {
  display: flex;
  /* justify-self: center; */
  padding: 41px 0 43px 0;
  justify-content: center;
}
.recharge .el-date-editor .el-range-separator {
  font-size: 15px;
  color: #ccc;
}
.unit-ri {
  width: 100%;
}
.unit-ri .block {
  display: flex;
  justify-content: flex-start;
  align-items: center; /* 垂直居中 */
}
.unit-ri .unit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
