<template>
  <div class="vip">
    <div class="vip-header"></div>
    <div class="vip-body" :style="{width: '100%',background: '#F3F3F3',padding: '34px 27px 0px 27px'}">
      <div :style="{width: '93%', backgroundColor: '#fff', marginTop: '108px', padding: '20px 10px 20px 20px'}">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="充值分析" name="first">
              <iconUnit></iconUnit>
              <!-- 充值分析   -->
          </el-tab-pane>
          <el-tab-pane label="充值排名" name="second">
            <div class="rank-form">
              <el-form ref="ranking" :model="ranking" name="ranking" :inline="true" label-width="100px">
                <el-form-item label="时间" prop="date">
                  <el-date-picker
                    v-model="ranking.date"
                    type="daterange"
                    range-separator="~"
                    :picker-options="pickerOptions"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="选择人员" prop="person" v-show="quan">
                  <el-input @click.native="choosePer" v-model="ranking.person"></el-input>
                </el-form-item>
                <el-form-item label="选择部门" prop="depment" v-show="quan">
                  <el-select v-model="ranking.depment" filterable placeholder="请选择" multiple @change="handleChangeDepment">
                    <el-option
                      v-for="item in depments"
                      :key="item.id"
                      :label="item.label"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button @click="searchData">查询</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div>
              <el-table :data="detailData" height="580" :loading="tableLoading">
                <el-table-column label="人员" prop="staff_name"></el-table-column>
                <el-table-column label="部门" prop="dept_name"></el-table-column>
                <el-table-column label="客单价" prop="cust_unit_price" sortable>
                  <template slot-scope="scope">
                    <div style="display: flex;">
                      <div class="rank" :style="{height: scope.row.height}">{{ scope.row.rank_cust_unit_price }}</div>
                      <div style="width: 5px;"></div>
                      <div :style="{width: '100%', height: scope.row.height, backgroundColor: '#fff', color: '#000',display: 'flex', aligItems: 'center', justifyContent: 'center'}" v-if="scope.row.buyPricesWidth < 1">{{ scope.row.cust_unit_price }}</div>
                      <div :style="{width: scope.row.buyPricesWidth + '%', height: scope.row.height, backgroundColor: scope.row.backgroundColor, color: '#fff',display: 'flex', aligItems: 'center', justifyContent: 'center'}" v-if="scope.row.buyPricesWidth > 1">{{ scope.row.cust_unit_price }}</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="充值金额" prop="amt_add" sortable>
                  <template slot-scope="scope">
                    <div style="display: flex;">
                      <div class="rank" :style="{height: scope.row.height}">{{ scope.row.rank_amt_topup }}</div>
                      <div style="width: 5px;"></div>
                      <div :style="{width: '100%', height: scope.row.height, backgroundColor: '#fff', color: '#000',display: 'flex', aligItems: 'center', justifyContent: 'center'}" v-if="scope.row.priceWidth < 1">{{ scope.row.amt_add }}</div>
                      <div :style="{width: scope.row.priceWidth + '%', height: scope.row.height, backgroundColor: scope.row.backgroundColor, color: '#fff',display: 'flex', aligItems: 'center', justifyContent: 'center'}" v-if="scope.row.priceWidth > 1">{{ scope.row.amt_add }}</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="客户数量" prop="cust_num" sortable>
                  <template slot-scope="scope">
                    <div style="display: flex;">
                      <div class="rank" :style="{height: scope.row.height}">{{ scope.row.rank_cust_num }}</div>
                      <div style="width: 5px;"></div>
                      <div :style="{width: '100%', height: scope.row.height, backgroundColor: '#fff', color: '#000',display: 'flex', aligItems: 'center', justifyContent: 'center'}" v-if="scope.row.peopleNumWidth < 1">{{ scope.row.cust_num }}</div>
                      <div :style="{width: scope.row.peopleNumWidth + '%', height: scope.row.height, backgroundColor: scope.row.backgroundColor, color: '#fff',display: 'flex', aligItems: 'center', justifyContent: 'center'}" v-if="scope.row.peopleNumWidth > 1">{{ scope.row.cust_num }}</div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div style="text-align: right;padding-top: 20px;">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage4"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageSize"
                layout="sizes, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <el-dialog title="选择人员" :center="true" :show-close='false' append-to-body :visible.sync="lianxiren">
            <div class="tiao_bumen">
                <div class="xuanze_left">
                    <p class="tit">选择：</p>
                    <!-- <div class="xuanze_sousuo">
                        <i class="el-icon-search"></i>
                        <input type="text" placeholder="搜索" @keyup="keyu" v-model="text">
                    </div> -->
                    <div class="mianbaoxie">
                        <div v-for="(i,index) in mianbao_list" :key='index'  @click="mianbao_dian(i,index)">
                            <p :class="mianbao_list.length-1==index?'p1':'p2'">{{i.name}}</p>
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class="quanxuan" v-if="qiye_renyuan_list.length!=0">
                        <!-- <input type="checkbox" @click="dianji_quanxuan" ref='quan'> -->
                        <img @click="dianji_quanxuan" :src="renyuan_quanxuan?require('../assets/okblue.png'):require('../assets/noccc.png')" alt="">
                        <span>全选</span>
                    </div>
                    <div class="nei_box">
                        <div class="liebiao">
                            <ul>
                                <li v-for="(i,index) in qiye_bumen_list" :key="index" @click="dianji_bumen(i)">
                                    <p style="margin-left:0.16rem;">{{i.label}}</p>
                                    <!-- <img class="img1" :src="i.child_flag=='0'?require('../assets/chazi.png'):require('../assets/chazi_ok.png')" alt=""> -->
                                </li>
                            </ul>
                            <ul>
                                <li v-for="(i,index) in qiye_renyuan_list" @click="dianji_xuanren(i)" :key="index" style='justify-content:left;margin-left:-0.07rem;'>
                                    <!-- <input ref="have" type="checkbox" @click="dianji_xuanren(i,$event,index)"> -->
                                    <img class="img2" :src="i.zhi?require('../assets/ok_blue.png'):require('../assets/noccc.png')" alt="">
                                    <p>{{i.name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="xuanze_right">
                    <p class="tit">已选：</p>
                    <div class="nei_box">
                        <div class="liebiao">
                            <ul>
                                <li v-for="(i,index) in qiye_renyuan_list_xuan" :key="index">
                                    <p>{{i.name}}--{{i.dept_name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="foote">
                <p class="queding" @click="renyuan_sure">确定</p>
                <p class="quxiao" @click="lianxiren=false">取消</p>
            </div>
        </el-dialog>
  </div>
</template>
<script>
import iconUnit from './VipAnalysis.vue'
import { getRank, getDeptList } from '@/api/vip.js'
import { query_ent_dept_list_aim_tile, query_dept_staff_list, query_ent_dept_list, query_as_staff_aim_log, update_as_staff_aim, insert_as_staff_aim, supply_as_staff_aim_data, query_as_staff_aim, query_as_year_list, query_user_info } from '@/api/api.js'
export default {
  data () {
    return {
      height: '',
      heightSmall: '',
      activeName: 'first',
      ranking: {
        date: [],
        person: [],
        depment: ''
      },
      mianbao_list: [],
      qiye_renyuan_list: [],
      qiye_bumen_list: [],
      qiye_renyuan_list_xuan: [],
      zhipai_liebiao: false,
      pageSize: 10,
      total: 0,
      quan: false,
      lianxiren: false,
      currentPage: 1,
      tableLoading: false,
      maxDate: '',
      minDate: '',
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          // 当我们选择两个值的时候，就认为用户已经选择完毕
          if (maxDate != null && minDate != null) {
            this.maxDate = maxDate
            this.minDate = minDate
          }
        },
        disabledDate: time => {
          const maxDate = this.maxDate
          const minDate = this.minDate
          if (maxDate != null && minDate != null) {
            const days = maxDate.getTime() - minDate.getTime()
            this.maxDate = null
            this.minDate = null
            if (parseInt(days / (1000 * 60 * 60 * 24)) > 30) {
              this.$message.error('不能超过一个月的日期')
              this.$nextTick(() => {
                this.ranking.date = null
              })
            }
            return false
          } else {
            return time.getTime() > Date.now()
          }
        }
      },
      text: '',
      mianbao_list: [
        {
          name: '',
          dept_id: '0'
        }
      ],
      persons: [],
      depments: [],
      disabled: false,
      ddisabled: false,
      detailData: [],
      currentPage4: 4
    }
  },
  components: { iconUnit },
  created () {
    this.height = window.innerHeight - 37
    this.heightSmall = this.height - 110
    this.mianbao_list[0].name = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name
    this.query_user_info()
  },
  methods: {
    async query_user_info() {
      try{
        let param = {
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
          }
        }
        const res = await query_user_info(param)
        console.log(res)
        if(res.data.body.staff_level > 0 && res.data.body.dept_manager >= 0 && res.data.body.aim_flag == 0) {
          this.quan = true
        } else {
          this.quan = false
        }
      }
      catch {}
    },
    async getRank(param) {
      try{
        this.detailData = []
        this.tableLoading = true
          const res = await getRank(param)
          if(res.data.code === '200') {
            this.tableLoading = false
            this.total = JSON.parse(res.data.body.data).total
            this.pageSize = JSON.parse(res.data.body.data).pageSize
            this.detailData = JSON.parse(res.data.body.data)
            let maxPrice = ''
            const priceWidth = ''
            let maxBuyPrice = ''
            const buyPriceWidth = ''
            let maxPeopleNum = ''
            const peopleNumWidth = ''
            const prices = []
            const buyPrices = []
            const peopleNums = []
            this.detailData.forEach(item => {
              peopleNums.push(item.cust_num)
              prices.push(item.amt_add)
              buyPrices.push(item.cust_unit_price)
            })
            maxPrice = prices.sort((a, b) => b - a)[0]
            maxBuyPrice = buyPrices.sort((a, b) => b - a)[0]
            maxPeopleNum = peopleNums.sort((a, b) => b - a)[0]
            for (let i = 0; i < this.detailData.length; i++) {
              this.detailData[i].priceWidth = Math.round((this.detailData[i].amt_add / maxPrice) * 100)
              this.detailData[i].buyPricesWidth = Math.round((this.detailData[i].cust_unit_price / maxBuyPrice) * 100)
              this.detailData[i].peopleNumWidth = Math.round((this.detailData[i].cust_num / maxPeopleNum) * 100)
              this.detailData[i].height = '31px'
              this.detailData[i].backgroundColor = '#BCAFE9'
            }
          }
      }
      catch(err){
        console.log(err)
        this.tableLoading = false
      }
    },
    handleChangePerson () {
      if (this.ranking.person.length !== 0 || this.ranking.person !== null) {
        this.disabled = false
        this.ddisabled = true
      }
      if (this.ranking.person.length === 0) {
        this.disabled = false
        this.ddisabled = false
      }
    },
    handleChangeDepment () {
      let param = ''
      if(this.ranking.date) {
        param = {
          data:{
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            dept_ids: this.ranking.depment.join(','),
            staff_ids: '',
            page_size: '10',
            page_index: '1',
            date_start: this.ranking.date[0],
            date_end: this.ranking.date[1]
          }
        }
      } else {
        let date = new Date()
          let y = date.getFullYear()
          let m = date.getMonth()+1
          if(m < 10){
            m = '0' + m
          }
          console.log(m)
          let d = date.getDate()
          if(d < 10) {
            d = '0' + d
          }
          let formatnowdate = y+'-'+m+'-'+d
          date.setMonth(date.getMonth()-1)
          var ago = new Date(date.getTime() - 30*24*60*60*1000) //30天前
          let day = ('0' + (ago.getDate() - 1)).slice(-2);
          let month = ('0' + (ago.getMonth() + 2)).slice(-2);
          let oneMonAgo = ago.getFullYear() + '-' + month + '-' + day ;
          param = {
            data: {
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id,
              dept_ids: this.ranking.depment.join(','),
              staff_ids: '',
              page_size: '10',
              page_index: '1',
              date_start: oneMonAgo,
              date_end: formatnowdate
            }
          }
      }
      this.getRank(param)
    },
    handleSizeChange (val) {
      this.pageSize = val
    },
    handleCurrentChange (val) {
      this.currentPage = val
    },
    searchData() {
      let param = {
        data:{
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          dept_ids: '',
          staff_ids: '',
          page_size: '10',
          page_index: '1',
          date_start: this.ranking.date[0],
          date_end: this.ranking.date[1]
        }
      }
      console.log(this.ranking.date)
      this.getRank(param)
    },
    async getDeptList(data) {
      try{
        const res = await getDeptList(data)
        console.log(res)
        const date = JSON.parse(res.data.body.data)
        date.map(item => {
          this.depments.push({
            id: item.dept_id,
            label: item.label
          })
        })
        console.log(this.depments)
      }
      catch{}
    },
    handleClick(tab) {
      console.log(tab)
      if(tab.name === "second") {
        let date = new Date()
          let y = date.getFullYear()
          let m = date.getMonth()+1
          let d = date.getDate()
          let formatnowdate = y+'-'+m+'-'+d
          date.setMonth(date.getMonth()-1)
          var ago = new Date(date.getTime() - 30*24*60*60*1000) //30天前
          let day = ('0' + (ago.getDate() - 1)).slice(-2);
          let month = ('0' + (ago.getMonth() + 2)).slice(-2);
          let oneMonAgo = ago.getFullYear() + '-' + month + '-' + day ;
          let param = {
            data: {
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id,
              search_str: null,
              page_size: '10',
              page_index: '1',
              date_start: oneMonAgo,
              date_end: formatnowdate
            }
          }
        this.getRank(param)
        let data = {
          data: {
            ent_id: this.$ent_id()
          }
        }
        this.getDeptList(data)
      }
    },
    choosePer() {
      this.dakai_bumen()
      this.lianxiren = true
    },
    dakai_bumen () {
      this.mianbao_list.splice(1, this.mianbao_list.length)
      this.bumen_liebiao()
    },
    bumen_liebiao () {
      query_ent_dept_list_aim_tile({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        //console.log(res)
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          this.qiye_bumen_list = date
          this.qiye_bumen_list_beiyong = date
        } else if (res.data.code == 10169) {}
      })
      // query_ent_dept_list({data:{
      //     ent_id:this.$ent_id(),
      //     active:'1',
      //     parent_id:this.mianbao_list[this.mianbao_list.length-1].dept_id
      // }}).then(res=>{
      //     //console.log(res)
      //     if(res.data.code==10168){
      //         let date=JSON.parse(res.data.body.data)
      //         //console.log(date)
      //         this.qiye_bumen_list=date
      //     }else if(res.data.code==10169){
      //     }
      // })
      // query_dept_staff_list({data:{
      //     ent_id:this.$ent_id(),
      //     user_id:this.$jichuxinxi().user_id,
      //     dept_id:this.mianbao_list[this.mianbao_list.length-1].dept_id,
      //     active:'1'
      // }}).then(ras=>{
      //     if(ras.data.code==10149){
      //         let listdata=JSON.parse(ras.data.body.data)
      //         listdata.map(item=>{item['zhi']=false})
      //         //console.log(99999,listdata)
      //         this.qiye_renyuan_list=listdata
      //         this.panduan_yixuan()
      //     }else if(ras.data.code==10150){
      //     }
      // })
    },
    dianji_bumen (i) {
      //console.log(44444, i)
      // if(i.child_flag==1){
      query_ent_dept_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          parent_id: i.dept_id,
          active: '1'
        }
      }).then(res => {
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          //console.log(date)
          this.qiye_bumen_list = date
          this.mianbao_list.push({ name: i.label, dept_id: i.dept_id })
          query_dept_staff_list({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id,
              dept_id: i.dept_id,
              active: '1'
            }
          }).then(ras => {
            if (ras.data.code == 10149) {
              const listdata = JSON.parse(ras.data.body.data)
              //console.log(this.tiaozheng_neirong)
              const list2 = []
              this.detailData.forEach(item => {
                if (item.dept_id == i.dept_id) {
                  list2.push(item)
                }
              })
              //console.log(list2)
              setTimeout(() => {
                for (let item = 0; item < listdata.length; item++) {
                  //console.log(listdata[item].staff_id)
                  list2.forEach(ite => {
                    //console.log(ite)
                    if (ite.staff_id == listdata[item].staff_id) {
                      this.$set(listdata[item], 'teshu', true)
                    }
                  })
                }
                setTimeout(() => {
                  const list = []
                  listdata.forEach(item => {
                    if (item.teshu == undefined) {
                      list.push(item)
                      item.zhi = false
                    }
                  })
                  //console.log(99999, list)
                  this.qiye_renyuan_list = list
                  this.panduan_yixuan()
                }, 0)
              }, 0)
            } else if (ras.data.code == 10150) {
            }
          })
        } else if (res.data.code == 10169) {
        }
      })
      // }else{
      //     this.$message({
      //         message: '该部门没有子部门了',
      //         type: 'warning'
      //     });
      // }
    },
    mianbao_dian (i, index) {
      //console.log(i.dept_id)
      if (index == 0) {
        this.qiye_bumen_list = this.qiye_bumen_list_beiyong
        this.mianbao_list.splice(index + 1, this.mianbao_list.length)
        this.qiye_renyuan_list = []
      } else {
        if (this.mianbao_list.length - 1 != index) {
          query_ent_dept_list({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id,
              parent_id: i.dept_id,
              active: '1'
            }
          }).then(res => {
            if (res.data.code == 10168) {
              var date = JSON.parse(res.data.body.data)
              // //console.log(date)
              this.qiye_bumen_list = date
              this.mianbao_list.splice(index + 1, this.mianbao_list.length)
              // query_dept_staff_list({data:{
              //     ent_id:this.$ent_id(),
              //     user_id:this.$jichuxinxi().user_id,
              //     dept_id:i.dept_id,
              //     active:'1'
              // }}).then(ras=>{
              //     //console.log(ras)
              //     if(ras.data.code==10149){
              //         let listdata=JSON.parse(ras.data.body.data)
              //         listdata.map(item=>{item['zhi']=false})
              //         //console.log(99999,listdata)
              //         this.qiye_renyuan_list=listdata
              //         this.panduan_yixuan()
              //     }else if(ras.data.code==10150){
              //     }
              // })
            } else if (res.data.code == 10169) {
            }
          })
        }
      }
    },
    dianji_quanxuan () {
      this.renyuan_quanxuan = !this.renyuan_quanxuan
      if (this.renyuan_quanxuan) {
        for (let i = 0; i < this.qiye_renyuan_list_xuan.length; i++) {
          if (this.qiye_renyuan_list_xuan[i].dept_id == this.mianbao_list[this.mianbao_list.length - 1].dept_id) {
            this.qiye_renyuan_list_xuan.splice(i, 1)
            i = i - 1
          }
        }
        setTimeout(() => {
          this.qiye_renyuan_list.forEach(item => {
            item.zhi = true
            this.$set(item, 'dept_id', this.mianbao_list[this.mianbao_list.length - 1].dept_id)
            this.$set(item, 'dept_name', this.mianbao_list[this.mianbao_list.length - 1].name)
            this.qiye_renyuan_list_xuan.push(item)
          })
        }, 0)
        // this.qiye_renyuan_list_xuan=this.$func.Es5duplicate(this.qiye_renyuan_list_xuan,'staff_id')
      } else {
        this.qiye_renyuan_list.forEach(item => { item.zhi = false })
        // for(let i=0;i<this.qiye_renyuan_list.length;i++){
        //     for(let a=0;a<this.qiye_renyuan_list_xuan.length;a++){
        //         if(this.qiye_renyuan_list[i].staff_id==this.qiye_renyuan_list_xuan[a].staff_id){
        //             this.qiye_renyuan_list_xuan.splice(a,1)
        //         }
        //     }
        // }
        for (let i = 0; i < this.qiye_renyuan_list_xuan.length; i++) {
          if (this.qiye_renyuan_list_xuan[i].dept_id == this.mianbao_list[this.mianbao_list.length - 1].dept_id) {
            this.qiye_renyuan_list_xuan.splice(i, 1)
            i = i - 1
          }
        }
      }
    },
    panduan_yixuan () {
      const list = []
      for (let i = 0; i < this.qiye_renyuan_list.length; i++) {
        for (let a = 0; a < this.qiye_renyuan_list_xuan.length; a++) {
          if (this.qiye_renyuan_list[i].staff_id == this.qiye_renyuan_list_xuan[a].staff_id && this.mianbao_list[this.mianbao_list.length - 1].dept_id == this.qiye_renyuan_list_xuan[a].dept_id) {
            this.qiye_renyuan_list[i].zhi = true
            list.push('1111')
          }
        }
      }
      if (this.qiye_renyuan_list.length != 0) {
        if (list.length == this.qiye_renyuan_list.length) {
          this.renyuan_quanxuan = true
        } else {
          this.renyuan_quanxuan = false
        }
      }
    },
    dianji_xuanren (i) {
      i.zhi = !i.zhi
      if (i.zhi) {
        this.$set(i, 'dept_id', this.mianbao_list[this.mianbao_list.length - 1].dept_id)
        this.$set(i, 'dept_name', this.mianbao_list[this.mianbao_list.length - 1].name)
        this.qiye_renyuan_list_xuan.push(i)
      } else {
        for (let a = 0; a < this.qiye_renyuan_list_xuan.length; a++) {
          if (i.staff_id == this.qiye_renyuan_list_xuan[a].staff_id && i.dept_id == this.qiye_renyuan_list_xuan[a].dept_id) {
            this.qiye_renyuan_list_xuan.splice(a, 1)
          }
        }
      }
      this.panduan_shifou_quanxuan()
    },
    panduan_shifou_quanxuan () {
      const list = []
      //console.log(this.qiye_renyuan_list)
      for (let i = 0; i < this.qiye_renyuan_list.length; i++) {
        if (this.qiye_renyuan_list[i].zhi) {
          list.push('1111')
        }
      }
      if (this.qiye_renyuan_list.length != 0) {
        if (list.length == this.qiye_renyuan_list.length) {
          this.renyuan_quanxuan = true
        } else {
          this.renyuan_quanxuan = false
        }
      }
    },
    renyuan_sure () {
      
      //console.log(this.qiye_renyuan_list_xuan)
      const list = []
      this.qiye_renyuan_list_xuan.forEach(item => {
        if (item.zhi) {
          list.push(item)
        }
      })
      let spanArr2 = list
      let op = []
      console.log(spanArr2)
      this.persons = []
      spanArr2.map(item => {
        this.persons.push(item.staff_name)
        op.push(item.staff_id)
      })
      this.ranking.person =  this.persons.join(',')
      //console.log(this.tiaozheng_neirong)
      this.lianxiren = false
      let param = ''
      if(op.length != 0) {
        op = op.join(',')
      } else {
        op = ''
      }
      if(this.ranking.date) {
        param = {
          data:{
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            dept_ids: '',
            staff_ids: op,
            page_size: '10',
            page_index: '1',
            date_start: this.ranking.date[0],
            date_end: this.ranking.date[1]
          }
        }
      } else {
        let date = new Date()
          let y = date.getFullYear()
          let m = date.getMonth()+1
          if(m < 10){
            m = '0' + m
          }
          console.log(m)
          let d = date.getDate()
          if(d < 10) {
            d = '0' + d
          }
          let formatnowdate = y+'-'+m+'-'+d
          date.setMonth(date.getMonth()-1)
          var ago = new Date(date.getTime() - 30*24*60*60*1000) //30天前
          let day = ('0' + (ago.getDate() - 1)).slice(-2);
          let month = ('0' + (ago.getMonth() + 2)).slice(-2);
          let oneMonAgo = ago.getFullYear() + '-' + month + '-' + day ;
          param = {
            data: {
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id,
              dept_ids: '',
              staff_ids: op,
              page_size: '10',
              page_index: '1',
              date_start: oneMonAgo,
              date_end: formatnowdate
            }
          }
      }
      this.getRank(param)
    },
    keyu () {
      if (this.text.length != 0) {
        get_staff_list_like({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            staff_name: this.text != 0 ? this.text : null,
            active: '1'
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10027) {
            console.log(JSON.parse(res.data.body.data))
            const date = JSON.parse(res.data.body.data)
            date.forEach(item => {
              this.$set(item, 'zhi', false)
              this.$set(item, 'name', item.staff_name)
            })
            this.depmented = false
            this.qiye_renyuan_list = date
            this.panduan_yixuan()
          } else if (res.data.code == 10028) {
          }
        })
      } else {
        this.qiye_renyuan_list = []
      }
    },
    // dateChange() {
    //   let time = Number(this.ranking.date[0])
    //   let time1 = Number(this.ranking.date[1])
    //   let day = this.ranking.date[1].setMonth((this.ranking.date[1].getMonth(-1)))
    //   console.log(time, 111111)
    //   console.log(time1, 222222)
    //   console.log(day, 333333)
    //   if(time >=  day) {
    //     console.log(1)
    //   }
    // }
  }
}
</script>
<style scoped lang="scss">
  .vip{
    width: 100%;
    overflow: hidden;
    .vip-header{
      position: fixed;
      top: 0;
      z-index:11;
      width: 100%;
      height: 78px;
      background: #fff;
    }
    .vip-body{
      z-index:2;
    }
  }
  .rank-form{
    padding-top: 32px;
    padding-left: 40px;
  }
  /deep/ .el-select__input{
    max-width: 278.75px;
  }
  /deep/ .el-intput{
    width: auto;
  }
  /deep/ .el-button{
    width: 100px;
    height: 34px;
    background: #9A86DB;
    border-radius: 10px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    padding-bottom: 12px;
  }
  .rank{
    background: url('../assets/rank.png') no-repeat;
    background-size: 100% 100%;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    height: 20px;
  }
  .tiao_bumen{
    display: flex;
    align-items: center;
    justify-content:center;
    .xuanze{
        height:3.54rem;
        width:7rem;
        background: #E7E8EB;
        margin: 0 0.11rem;
        margin: 0.2rem 0;
        position: relative;
        overflow-y:auto;
        &::-webkit-scrollbar {
            width: 0.2rem;
            height: 0.08rem;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0.12rem;
            border: 0.06rem solid rgba(0, 0, 0, 0);
            box-shadow: 0.08rem 0 0 #A5ADB7 inset;
        }

        &::-webkit-scrollbar-thumb:hover {
            box-shadow: 0.08rem 0 0 #4A4A4A inset;
        }
        .tit{
            position: absolute;
            left:0;
            top:-0.3rem;
            font-size: 0.14rem;
            color:#1A2533;
        }
        .xuanze_sousuo{
            position: relative;
            i{
                position: absolute;
                top:0.09rem;;
                left: 0.12rem;
            }
            input{
                height:0.3rem;
                width:6rem;
                border:none;
                background:#fff;
                padding: 0 0.33rem;
                margin: 0 auto;
                display: block;
                margin-top: 0.15rem;
                border-radius:0.02rem;
            }
        }
        .mianbaoxie{
            display: flex;
            // align-items: center;
            // justify-content: left;
            flex-wrap: wrap;
            margin-bottom: 0.1rem;
            margin-left: 0.23rem;
            margin-top:0.12rem;
            div{
                display: flex;
                align-items: center;
                cursor:pointer;
                p{
                    font-size: 0.14rem;
                }
                .p2{
                    color:#9a86db;
                }
                .p1{
                    color:#1A2533;
                }
            }
        }
        .nei_box{
            width:6.6rem;
            margin: 0 auto;
            min-height: 2.4rem;
            overflow:auto;
            // overflow-y: hidden;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 0.19rem;
                height: 0;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 0.12rem;
                border: 0.06rem solid rgba(0, 0, 0, 0);
                box-shadow: 0.08rem 0 0 #A5ADB7 inset;
            }

            &::-webkit-scrollbar-thumb:hover {
                box-shadow: 0.08rem 0 0 #4A4A4A inset;
            }
            .liebiao{
                width:6.4rem;
                margin: 0 auto;
                ul{
                    li{
                        display: flex;
                        height:0.3rem;
                        justify-content: space-between;
                        align-items: center;
                        color:#1A2533;
                        font-size: 0.12rem;
                        padding-left: 0.1rem;
                        cursor:pointer;
                        &:hover{
                            background: #eee;
                        }
                        .img1{
                            height:0.11rem;
                            width:0.2rem;
                        }
                        .img2{
                            height:0.13rem;
                            width:0.13rem;
                            margin: 0 0.1rem;
                        }
                        p{
                            display: flex;
                            align-items: center;
                        }
                    }
                }
            }
            .el-icon-search{
                position: absolute;
                top:0.22rem;
                left:0.34rem;
            }
            .el-tree{
                margin-top:0.2rem;
                padding: 0 0.21rem;
            }
            .custom-tree-node{
                display: flex;
                align-items: center;
                img{
                    height:0.14rem;
                    width:0.15rem;
                    display: inline-block;
                    margin-right:0.14rem;
                }
                font-size: 0.12rem;
                color:#1A2533;
            }
        }
    }
    .xuanze_left,.xuanze_right{
        width:49%;
        height:3.54rem;
        background: #E7E8EB;
        margin: 0 0.11rem;
        margin-top: 0.2rem;
        position: relative;
        .tit{
            position: absolute;
            left:0;
            top:-0.3rem;
            font-size: 0.14rem;
            color:#1A2533;
        }
        .xuanze_sousuo{
            position: relative;
            i{
                position: absolute;
                top:0.09rem;;
                left: 0.19rem;
            }
            input{
                height:0.3rem;
                width:2.74rem;
                border:none;
                background:#fff;
                padding: 0 0.33rem;
                margin: 0 auto;
                display: block;
                margin-top: 0.15rem;
                border-radius:0.02rem;
            }
        }
        .mianbaoxie{
            display: flex;
            // align-items: center;
            // justify-content: left;
            flex-wrap: wrap;
            margin-bottom: 0.1rem;
            margin-left: 0.23rem;
            margin-top:0.12rem;
            div{
                display: flex;
                align-items: center;
                cursor:pointer;
                p{
                    font-size: 0.14rem;
                }
                .p2{
                    color:#9a86db;
                }
                .p1{
                    color:#1A2533;
                }
            }
        }
        .quanxuan{
            display: flex;
            align-items: center;
            margin-left: 0.24rem;
            img{
                height:0.16rem;
                width:0.16rem;
                margin-right: 0.08rem;
                cursor: pointer;
            }
            span{
                color:#1A2533;
                font-size: 0.12rem;
            }
        }
        .nei_box{
            width:3.45rem;
            margin: 0 auto;
            max-height: 2.4rem;
            overflow:auto;
            // overflow-y: hidden;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 0.19rem;
                height: 0;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 0.12rem;
                border: 0.06rem solid rgba(0, 0, 0, 0);
                box-shadow: 0.08rem 0 0 #A5ADB7 inset;
            }

            &::-webkit-scrollbar-thumb:hover {
                box-shadow: 0.08rem 0 0 #4A4A4A inset;
            }
            .liebiao{
                width:3.25rem;
                margin: 0 auto;
                ul{
                    li{
                        display: flex;
                        height:0.3rem;
                        // justify-content: space-between;
                        align-items: center;
                        color:#1A2533;
                        font-size: 0.12rem;
                        padding-left: 0.1rem;
                        cursor:pointer;
                        &:hover{
                            background: #eee;
                        }
                        .img1{
                            height:0.11rem;
                            width:0.2rem;
                        }
                        .img2{
                            height:0.16rem;
                            width:0.16rem;
                            margin-right: 0.08rem;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    .xuanze_right{
        .nei_box{
            .liebiao{
                ul{
                    li{
                        justify-content: space-between;
                    }
                }
            }
        }
    }
}
.foote{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.21rem;
    p{
        margin: 0 0.23rem;
        height:0.43rem;
        width:1.36rem;
        border-radius: 0.02rem;
        font-size: 0.14rem;
        line-height: 0.43rem;
        text-align: center;
        font-weight: bold;
        cursor:pointer;
    }
    .queding{
        color:#fff;
        background: #9a86db;
    }
    .quxiao{
        color: #888888;
        border:0.01rem solid #888888;
        background: #fff;
    }
}
</style>
